import React, {useState, useEffect} from 'react'
import { Grid, Checkbox,Divider, ButtonGroup  , List, ListSubheader, ListItem, ListItemText , Box, Button} from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


function GroupsRender({ response }) {
  const data = Object.entries(response).map(([key, values]) => ({ key, values }));

  console.log("data is ",data)
  const [array, setArray] = useState([]);
  const [copyCLipText, setCopyCLipText] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("All Topics"); // add this line

  const keys = Object.keys(response);
  const values = Object.values(response);

  const handleChange = (item) => (event) => {
    setArray([...array, item]);
  };

  useEffect(() => {
    setCopyCLipText(array.join('\n'));
  }, [array]);

  const handleTopicChange = (topic) => {

    console.log(topic)

    setSelectedTopic(topic);
  };




  // const downloadExcel = () => {
  //   // Flatten the data array into an array of objects with a "key" and "value" property
  //   const flattenedData = data.flatMap(item => item.values.map(value => ({ key: item.key, value })));
  
  //   // Create the worksheet from the flattened data
  //   const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  
  //   // Create a new workbook and append the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  //   // Add a header row to the sheet
  //   XLSX.utils.sheet_add_aoa(worksheet, [["Group", "Keywords"]], { origin: "A1" });
  
  //   // Write the workbook to a file
  //   XLSX.writeFile(workbook, "DataSheet.xlsx");
  // };
  
  
  const downloadExcel = () => {


    let dataToExport;

    if (selectedTopic === "All Topics") {
      dataToExport = data;
    } else {
      dataToExport = data.filter(item => item.key === selectedTopic);
    }
  


    // Flatten the data array into an array of objects with a "key" and "value" property
    const flattenedData = dataToExport.flatMap(item => item.values.map(value => ({ key: item.key, value })));
  
    // Create the worksheet from the flattened data
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Add a header row to the sheet
    XLSX.utils.sheet_add_aoa(worksheet, [["Group", "Keywords"]], { origin: "A1" });
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  return (
    <Box>
      {/* <Box my={1}>
        <CopyToClipboard text={copyCLipText}>
          <Button className="hello" variant="contained">
            Copy to clipboard
          </Button>
        </CopyToClipboard>
        <Button variant="contained" color="primary" onClick={downloadExcel}>Export Selected Data</Button>

      </Box> */}

      <Box sx={{my: 1}}>
      <Grid container >
      <Box component={Grid} item md={2}  display={{ xs: "none", md: "block" }} >
         {/* Selected */}
        </Box>
      
        <Grid item xs={6} md={7} lg={7} textAlign={{ xs: "left", md: "right" }} >
        <CopyToClipboard variant="contained" sx={{mr: 2}} color="primary" text={copyCLipText} >
       <Button>Copy to clipboard</Button>
       </CopyToClipboard>
        </Grid>
        <Grid item xs={6} md={3} lg={3} textAlign="right">
        {/* <Button variant="contained" color="primary" onClick={()=>downloadExcel(populateData)}>Export Selected Data</Button> */}
        <Button variant="contained" color="primary" onClick={downloadExcel}>Export Keywords</Button>

        </Grid>
      </Grid>
    </Box>






      <Button onClick={() => handleTopicChange("All Topics")} variant="outlined">All Topics</Button>

      {keys.map((key) => (
        <Button onClick={() => handleTopicChange(key)} variant="outlined" className="buttonpad">{key}</Button>
      ))}

      <Grid container spacing={1}>
        {keys.map((key, index) => (
          <Grid item xs={3} key={key}>
            {selectedTopic === "All Topics" || key === selectedTopic ? (
              <Box className="mimi" my={1} id="style-4">
                <List
                  subheader={
                    <ListSubheader component="h2" className="huhuh">
                      {key.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </ListSubheader>
                  }
                >
                  
                  {values[index].map((item, index) => (
                    <>
                      <ListItem className="listColor" key={index} button>
                        <Checkbox {...label} onChange={handleChange(item)} />
                        <ListItemText primary={item} className="txtsetter" />
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </Box>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}





export default GroupsRender