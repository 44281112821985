import React, {useState} from 'react'
import GroupsRender from "../Component/GroupsRender"
import {Box, Container, TextareaAutosize} from "@mui/material"

function GroupingTool() {


    const [inputValue, setInputValue] = useState("")
    const inputArray = inputValue.split('\n');
  console.log(inputArray)

    const groupKeywords = (keywords) => {
        const groups = {};
        keywords.forEach((keyword) => {
          const words = keyword.split(" ");
          for (let i = 0; i < words.length; i++) {
            for (let j = i + 1; j < words.length; j++) {
              for (let k = j + 1; k < words.length; k++) {
                const group = `${words[i]} ${words[j]} ${words[k]}`;
                if (group.includes("in")) continue;
                if (groups[group]) {
                  if (!groups[group].includes(keyword)) {
                    groups[group].push(keyword);
                  }
                } else {
                  groups[group] = [keyword];
                }
              }
              const group = `${words[i]} ${words[j]}`;
              if (group.includes("in")) continue;
              if (words.length > 2 && groups[group]) {
                if (!groups[group].includes(keyword)) {
                  groups[group].push(keyword);
                }
              } else if (words.length > 2) {
                groups[group] = [keyword];
              }
            }
            if (words.length > 1) continue;
            const group = `${words[i]}`;
            if (group.includes("in")) continue;
            if (groups[group]) {
              if (!groups[group].includes(keyword)) {
                groups[group].push(keyword);
              }
            } else {
              groups[group] = [keyword];
            }
          }
        });
        return Object.keys(groups).reduce((filteredGroups, group) => {
          if (groups[group].length > 2) {
            filteredGroups[group] = groups[group];
          }
          return filteredGroups;
        }, {});
      
      };
      
      
      
      // const keywords = ["seo services in lahore", "seo experts in lahore", "seo company in lahore", "seo in lahore", "seo agency in lahore", "best seo services in lahore", "best seo company in lahore", "seo agency lahore", "top seo company in lahore", "affordable seo services in lahore", "seo software house in lahore", "monthly seo services in lahore", "top seo companies in lahore", "seo services in lahore 2021", "best seo services lahore"];
      
   const objs = groupKeywords(inputArray)
        


const filteredResponse = {};

for (const [key, value] of Object.entries(objs)) {
  if (Object.values(filteredResponse).findIndex(a => JSON.stringify(a) === JSON.stringify(value)) === -1) {
    filteredResponse[key] = value;
  }
}

console.log(filteredResponse);

  console.log(filteredResponse)

  return (
   
    <>
    <section>
          <Container>
      
    <Box my={2}>
    <TextareaAutosize  style ={{width: '100%', fontSize: "15px" }}  minRows={10} maxRows={10} onChange={(e) => setInputValue(e.target.value)} padding-left="12px" className="textareaEdits" />
    </Box>
    <Box>
      <GroupsRender response={filteredResponse} />
      </Box>  
      </Container>
      </section>
    </> 
 
    

  )
}

export default GroupingTool