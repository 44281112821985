import React from 'react'
import { BallTriangle } from  'react-loader-spinner';
import {Grid} from "@mui/material"

function LoadingCacheCompo() {
  return (
    <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={12}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
       
        </Grid> 
  )
}

export default LoadingCacheCompo