import React, {useState} from 'react';
import {Grid, TextField, Button, Box, Container, } from '@mui/material';
import axios from 'axios';
import { CirclesWithBar } from  'react-loader-spinner';

function BulkIndexer() {

    const [file, setFile] = useState(null);
    const [name, setName] = useState();
    const [status, setStatus] = useState("");
    const [responseData, setResponseData] = useState();
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState(false);

  // console.log(file)
  // console.log(name)


  // const handleSubmit = async (event) => {
  //   setStatus(""); // Reset status
  //       event.preventDefault();
  //       const formData = new FormData();
  //       formData.append("jsonKey", file);
  //       formData.append("name", name);
  //       console.log("Abc")
  //       const resp = await axios.post("https://kwintent-indexer.herokuapp.com", formData, {
  //         headers: {
  //           "content-type": "multipart/form-data",
  //         },
  //       });
  //       setStatus(resp.status === 200 ? "Thank you!" : "Error.");
  //       console.log(resp)

  //     };

  console.log(responseData);

      const  handleSubmit = async (e) => {
        e.preventDefault();
        // https://kwintent-google-scraper.herokuapp.com/ 
        setStatus(""); // Reset status
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        console.log("Abc")
         await axios.post("https://bulk-indexer.onrender.com", formData )

        //  , { https://kwintent-indexer.herokuapp.com
        //   // https://aatcourses156692366.wordpress.com/2021/11/30/aat-courses
        //    // headers: {'Content-Type': 'multipart/form-data'}
        //    headers: {'Content-Type': 'application/json'}
        //    }


            .then((response) => {
              setResponseData(response.data);
              setStatus(response.status);
              setLoading(false);
              setMessageError(false)
              
            }).catch((error) => {
            
              console.log(error)
              setMessageError(true)
              setLoading(false);
            }) 
        } 
              // https://aatcourses156692366.wordpress.com/2021/11/30/aat-courses
              // console.log(responseData)


  return (
    <>
    
    <Container > 
    <Box>

    <form onSubmit={handleSubmit}>

    <Grid container spacing={1} >

  <Grid item xs={12}>
        <Box my={2}>
            <TextField id="outlined-multiline-static" label="Paste Urls here" multiline rows={10}  style ={{width: '100%'}} required onChange={(e) => setName(e.target.value)} value={name} />
        </Box>
 </Grid>
  </Grid>
        {/* 2nd item */}

         <Grid container spacing={1} direction="column"   alignItems="center"   justifyContent="center">
            <Grid item xs={12}  > 
                
                    <Button variant="contained" component="label">
                             Upload Json Key
                        <input hidden accept=".json" multiple type="file" onChange={(e) => setFile(e.target.files[0])} />
                    </Button>
                    <Button variant="contained" type="submit" className="buttonSubmit"  onClick={() => setLoading(true)} >
                            Submit
                    </Button>
                
                
            </Grid>
   
            </Grid>
            </form>
          

            {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
        <Grid item md={7}>
        <CirclesWithBar color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }


        {status === 200 ? <h2 className="errorHeading56">✓ Links has been successfully submitted. Links will index within 24 hours</h2> : null}

        {messageError && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        } 


    </Box>
    </Container>
    
    </>
  )
}

export default BulkIndexer