import React, {useState} from "react";
import { Link as RouterLink,  } from "react-router-dom";
import { Container, Typography, Link, Box, TextField, Button } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../Images/icon_logo.png";
import { motion } from "framer-motion";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";


//////////////////////////////////
const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = () => {


  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)


    const [data, setData] = useState({    
        email: "",
        password: "",
      })


      const { email, password } = data

      const handleChange = e =>
        setData({ ...data, [e.target.name]: e.target.value })
    
      const handleSubmit = async e => {
        setLoading(true)
        e.preventDefault()
    
        // https://auth-kwintent.onrender.com/auth/login https://auth-kwintent.onrender.com
             await axios.post(
            "https://kwintentauthnew-h6lhegca5a-uc.a.run.app/auth/login",
            { email, password },
            { headers: { "Content-Type": "application/json" } }
          ).then((response) => {
            localStorage.setItem("tawk", response.data.token)
            localStorage.setItem("huh", response.data.token)
            setLoading(false)
            window.location.href = "https://keywordintent.io";
            // window.location.href = "http://localhost:3000";
          }).catch((error) => {
            setLoading(false)
            setMessage(error.response.data.error)
            
          })
         
          
        }       




        
  return (
    <RootStyle>
    <Container maxWidth="sm">
      <ContentStyle>
        <HeadingStyle component={motion.div} {...fadeInUp}>
        <Box>
        <Link to="/">
         <Box component="img" src={Logo} alt="logo" />
          </Link>
    </Box>
          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Login to your account
          </Typography>
        </HeadingStyle>

        <form autoComplete="off" onSubmit={handleSubmit} >
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            
          >
            <TextField
              fullWidth
              
              type="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={handleChange}
            />

            <TextField
              fullWidth
          
              type= "password"
              label="Password"
              name="password"
              value={password}
              onChange={handleChange}
            />

{loading && <BeatLoader color="#FF00DF" className="my-1" />}
                
                <Button type="submit" variant="contained">Login</Button>
                {message && <div className='message'>{message}</div>}
          </Box>
        </Box>
      </form>

      <Typography
  component={motion.p}
  {...fadeInUp}
  variant="body2"
  align="center"
  sx={{ mt: 3 }}
>
  Don’t have an account?{" "}
  <Link variant="subtitle2" component={RouterLink} to="/signup">
    Sign up
  </Link>
  {" | "} {/* This adds a separator for visual clarity */}
  Forgot your password?{" "}
  <Link variant="subtitle2" component={RouterLink} to="/password-reset">
    Reset password
  </Link>
</Typography>

      </ContentStyle>
    </Container>
  </RootStyle>
  );
};

export default Login;