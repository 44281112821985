import React, {useState} from 'react';
import { BallTriangle } from  'react-loader-spinner';
import {Box, Grid, TextField, Button, Container } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from "axios";
import PopulateGoogleScraper from "../Component/PopulateGoogleScraper"


const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    display: "block",
    width: "100%"
}

function GoogleScraper() {

    const [inputValue, setInputValue] = useState();
    const [loading, setLoading] = useState();
    const [peopleAlsoAsk, setPeopleAlsoAsk] = useState([]);
    const [messageerror, setMessageError] = useState(false);
    const [dataCopyToClip, setDataCopyToClip] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();


    const  handleSubmit = async (e) => {
        e.preventDefault();
        // https://kwintent-google-scraper.herokuapp.com/
       
         await axios.post("https://google-bing-api.onrender.com/", inputValue , {
     
        
            headers: {'Content-Type': 'text/plain'}
          })
            .then((response) => {
            console.log(response.data);
            setPeopleAlsoAsk(response.data);
              setLoading(false);
              setMessageError(false)
              
            }).catch((error) => {
            
              console.log(error)
              setMessageError(true)
              setLoading(false);
            }) 
        } 


        const customStyles = {
            headCells: {
              style: {
                backgroundColor: 'rgb(255, 0, 223)',
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: '17px',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
              },
            },
          };


          const columns = [
            {
            name: '# ',
            cell: (row, index) => index + 1,
            width: '49px',
            // sortFunction: caseInsensitiveSort
            },
            {
                name: 'Title',
                selector: row => row.title,
                // sortable: true,
                // width: '237px',
                width: '400px',
             
            },
            {
                name: 'Url',
                selector: row => row.link,
                // sortable: true,
                width: '380px',
                
            },
            {
                name: 'Article Description',
                selector: row => row.snippet,
                // sortable: true,
                // width: '400px',
                
            }
          ];



          const handleChange = ({ selectedRows }) => {
            // You can set state or dispatch with something like Redux so we can use the retrieved data
            console.log('Selected Rows: ', selectedRows);
          
            
            var arr1 = selectedRows.map(function(item){
              return [item.title, item.link, item.snippet];
          });
          
          
          var arr2 = selectedRows.map(function(item){
            return item.title ;
          });
          
          setDataCopyToClip(arr2.join('\n'))
          
          setSelectedQuestionData(arr1)
          };  


          if(selectedQuestionData !== undefined && selectedQuestionData.length !== 0){

            var lengthData = selectedQuestionData.length
            }


  return (
    <Container maxWidth="xl"> 
    <Box>
 
        <Grid item xs={12} mt={4}>
 
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9} >
            <TextField required style ={{width: '100%'}} label="Enter The Topic" variant="outlined" onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
            </Grid>
         
            <Grid item xs={12} md={3}>
                     <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)} >SUBMIT</Button>
              </Grid>
          </Grid>
        </form>

        {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


        {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }

<Box mt={7}>


{  peopleAlsoAsk !== undefined && peopleAlsoAsk.length !== 0 ?

<PopulateGoogleScraper populateData={selectedQuestionData} selectedRowsData={lengthData} copyToClipboard={dataCopyToClip} />
  : null
}


<DataTable columns={columns} data={peopleAlsoAsk} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChange} />
</Box>

        </Grid>
        </Box>
       </Container> 
  
  )
}

export default GoogleScraper;