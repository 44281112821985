import React, {useState , useEffect} from 'react'
import {Grid, TextField, Box, Container, Button, Radio, Checkbox, Typography } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { CirclesWithBar } from  'react-loader-spinner';
import PopulateSuperKgr from "../Component/PopulateSuperKgr"
import useLocalStorage from "use-local-storage";

var _ = require('lodash');


const buttonStyle = {
  backgroundColor: "#FF00DF", 
  fontWeight: "bold", 
  // marginTop: "16px",
  // marginBottom: "86px",
  marginLeft: "5px",
  borderRadius: "30px",
  paddingLeft: "30px",
  paddingRight: "30px"
};


const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    float: "right"
}

const customStyles = {
    headCells: {
      style: {
        backgroundColor: 'rgb(255, 0, 223)',
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: '17px',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
      },
    },
  };

function PaidSuperFastKGR() {
    const [formData, setFormData] = useState({
        name: "",
        // cseIdDone: "",
        // apiKeyDone: ""
    });

    const [idValue, setIdValue] = useLocalStorage("ids", "");
    
    const [googleAdsData, setGoogleAdsData] = useState([])
    const [googleAllInTitleData, setGoogleAllInTitleData] = useState([])
    const [superFinalData, setSuperFinalData] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataCopyToClip, setDataCopyToClip] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();
    const [messageerror, setMessageError] = useState(false);
    // const [checkError, setCheckError] = useState(false);

    // const [message, setMessage] = useState('')
    const [checked, setChecked] = useState(false);

    // const [message, setMessage] = useState()
    const [herokuCheck, setHerokuCheck] = useState(false)


    const [apivalue1, setSecretvalue1] = useLocalStorage("secret1", "");
    const [apivalue2, setSecretvalue2] = useLocalStorage("secret2", "");
    const [apivalue3, setSecretvalue3] = useLocalStorage("secret3", "");
    const [apivalue4, setSecretvalue4] = useLocalStorage("secret4", "");
    const [apivalue5, setSecretvalue5] = useLocalStorage("secret5", "");
    const [apivalue6, setSecretvalue6] = useLocalStorage("secret6", "");
    const [apivalue7, setSecretvalue7] = useLocalStorage("secret7", "");
    const [apivalue8, setSecretvalue8] = useLocalStorage("secret8", "");
    const [apivalue9, setSecretvalue9] = useLocalStorage("secret9", "");
    const [apivalue10, setSecretvalue10] = useLocalStorage("secret10", "");
    const [apivalue11, setSecretvalue11] = useLocalStorage("secret11", "");
    const [apivalue12, setSecretvalue12] = useLocalStorage("secret12", "");
    const [apivalue, setSecretvalue] = useState(apivalue1);




    const {name } = formData;
    console.log(name)
// console.log(selectedValue)

    const handleChange = (event) => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      };

      console.log(" names are", name)
        const finalkeywordsforKGR = name.split(/\n/).filter((a) => a)
        finalkeywordsforKGR.splice(99,999990)
        console.log(" finalkeywordsforKGR are", finalkeywordsforKGR)
      const handleSubmit = (event) => {
        event.preventDefault();
            // https://superfastkgr.herokuapp.com {"keywordValue": ["seo agency london"] ,"apiKeyDone": "AIzaSyDoB5tIRL9-lvoMlRdM4iCkIpg2hnauaSE", "cseIdDone": "f2bd4eb1bbc9c4381"}
            // https://superfastkgr100.herokuapp.com/inputData
            // setIdValue(cseIdDone)
            // setSecretvalue(apivalue)

            if (!checked) {
                return;
              }

              if((idValue.length >18 || idValue.length <16) || (apivalue.length > 40 || apivalue.length < 38)){
                return setHerokuCheck(true)
               } 
 
              
               setHerokuCheck(false)
              setLoading(true)

                // axios.post('https://freekwintentandextension.herokuapp.com/kgrkeywords',  name ,{
                //   headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'text/plain'} })

                 axios.post("https://keywordintent-m5tfx6i6ia-nn.a.run.app/kgrkeywords", {query: finalkeywordsforKGR} , {
    
                  headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'application/json'}
                })

                .then(response => {
                  setGoogleAdsData(response.data);
                  console.log(response.data)
                })
                .catch(error => {
                  // setMessageError(true)
                  // console.error(error);

                const errorData = name.split(/\n/).map(keyword => {
                    return Object.assign({}, {
                      keyword_idea_metrics: {
                        avg_monthly_searches: 10
                      },
                      text: keyword
                    });
                  });

                  console.log(errorData)

                  setGoogleAdsData(errorData)


                });
           
        console.log(formData);
      };


      useEffect(() => {
        if (googleAdsData.length !== 0) {
         
          const keywords = googleAdsData.map((data) => {
              return data.text
          })
          console.log(keywords) // "AIzaSyDoB5tIRL9-lvoMlRdM4iCkIpg2hnauaSE" "f2bd4eb1bbc9c4381"
            axios.post('https://keywordintent-m5tfx6i6ia-nn.a.run.app/allintitle', {"keywordValue": keywords ,"apiKeyDone": apivalue, "cseIdDone": idValue} , 
            { headers: {'Content-Type': 'application/json'}})
            .then(response => {
            setGoogleAllInTitleData(response.data);
            setLoading(false)
            setChecked(false)
            })
            .catch(error => {
              setMessageError(true)
              console.error(error);
            });
            
        }
      }, [googleAdsData]);


      useEffect(() => {
        if (googleAllInTitleData.length !== 0) {
         
            function customizer(firstValue, secondValue) {
                return Object.assign({}, firstValue, secondValue);
              }
              setSuperFinalData(_.mergeWith(googleAdsData, googleAllInTitleData, customizer))
           
        }
      }, [googleAllInTitleData]);





      const columns = [
        // {
        // name: '# ',
        // cell: (row, index) => index + 1,
        // width: '66px',
        // // sortFunction: caseInsensitiveSort
        // },
        {
            name: 'Keyword',
            selector: row => row.text,
            // sortable: true,
            // width: '237px',
            width: '210px',
         
        },
        {
            name: 'Search Volume',
            // selector: row =>  row.keyword_idea_metrics.avg_monthly_searches,
            selector: row =>  row.keyword_idea_metrics ? row.keyword_idea_metrics.avg_monthly_searches : 10,
            // sortable: true,
            center: true,
            // width: '175px',
          
        },
        {
            name: 'AllInTitle',
            selector: row => row.resultsNumber,
            sortable: true,
            center: true,
            // width: '170px',
            // width: '225px',
            
        },
        {
            name: 'KGR Ratio',
            // selector: row => (row.resultsNumber / row.keyword_idea_metrics.avg_monthly_searches).toFixed(2),
            selector: row => (row.resultsNumber / (row.keyword_idea_metrics?.avg_monthly_searches ?? 10)).toFixed(2),
          sortable: true,
            center: true,
            // width: '122px',
           
        }
      ];  
      

      const handleChangeSelected = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data

        var arr1 = selectedRows.map(function(item){
          return [item.text,  item.keyword_idea_metrics ? item.keyword_idea_metrics.avg_monthly_searches : 10, item.resultsNumber, (item.resultsNumber / (item.keyword_idea_metrics?.avg_monthly_searches ?? 10)).toFixed(2)];
      });
      //   var arr1 = selectedRows.map(function(item){
      //     return [item.text,  item.keyword_idea_metrics.avg_monthly_searches, item.resultsNumber, (item.resultsNumber / item.keyword_idea_metrics.avg_monthly_searches).toFixed(2)];
      // });
      
      
      var arr2 = selectedRows.map(function(item){
        return item.text ;
      });
      
      setDataCopyToClip(arr2.join('\n'))
      
      setSelectedQuestionData(arr1)
      };  


      if(selectedQuestionData !== undefined && selectedQuestionData.length !== 0){

        var lengthData = selectedQuestionData.length
        }

        const handleRadioChange = (event) => {
            setSecretvalue(event.target.value);
          };

          const handleCheckboxChange = (event) => {
            setChecked(event.target.checked);
          };

        console.log(idValue, apivalue)


const conditionalRowStyles = [
  {
    // when: row => (row.resultsNumber / row.keyword_idea_metrics.avg_monthly_searches).toFixed(2) < 0.25,
    when: row => row.keyword_idea_metrics ? ((row.resultsNumber / row.keyword_idea_metrics.avg_monthly_searches).toFixed(2) < 0.25) : ((row.resultsNumber / 10).toFixed(2) < 0.25),
    style: {
      backgroundColor: 'green',
      color: 'white'
    },
  },
  // You can also pass a callback to style for additional customization
  {
    // when: row => (row.resultsNumber / row.keyword_idea_metrics.avg_monthly_searches).toFixed(2) > 0.25,
    when: row => row.keyword_idea_metrics ? ((row.resultsNumber / row.keyword_idea_metrics.avg_monthly_searches).toFixed(2) > 0.25) : ((row.resultsNumber / 10).toFixed(2) > 0.25),
     style: {
      backgroundColor: 'red',
      color: 'white'
    },
  },
];





  return (
    <>

<Container> 
    <Box mt={6} mb={2}>
    {/* Main Grid */}
                <Grid container spacing={1}>

                    <Grid item xs={4}>
                    <form onSubmit={handleSubmit}>
                    
                         <TextField type="text" name="name" label="one keyword per line" multiline rows={6}  style ={{width: '100%'}} onChange={handleChange}  required />
                    
                        <Box my={1} >
                        
                            <TextField type="text" name="cseIdDone"  style ={{width: '100%'}} label="ID" variant="outlined"  onChange={(e) => {setIdValue(e.target.value);}} value={idValue} required />
                          
                      </Box>
                      {herokuCheck && <Box className='messageType'>Please Enter Correct ID And Api</Box>}
                        <Button variant="contained" align="right"  color="primary"  type="submit" style={submitButton} >SUBMIT</Button>
                      
                        <Box id="radioBox">
                        <Radio
                                checked={apivalue === apivalue1}
                                onChange={handleRadioChange}
                                value={apivalue1}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 1' }}
                                />
                                <Radio
                                checked={apivalue === apivalue2}
                                onChange={handleRadioChange}
                                value={apivalue2}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 2' }}
                                />      
                                <Radio
                                checked={apivalue === apivalue3}
                                onChange={handleRadioChange}
                                value={apivalue3}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 3' }}
                                />
                            <Radio
                                checked={apivalue === apivalue4}
                                onChange={handleRadioChange}
                                value={apivalue4}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 4' }}
                                />
                                <Radio
                                checked={apivalue === apivalue5}
                                onChange={handleRadioChange}
                                value={apivalue5}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 5' }}
                                />      
                                <Radio
                                checked={apivalue === apivalue6}
                                onChange={handleRadioChange}
                                value={apivalue6}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 6' }}
                                />
                            <Radio
                                checked={apivalue === apivalue7}
                                onChange={handleRadioChange}
                                value={apivalue7}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 7' }}
                                />
                                <Radio
                                checked={apivalue === apivalue8}
                                onChange={handleRadioChange}
                                value={apivalue8}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 8' }}
                                />      
                                <Radio
                                checked={apivalue === apivalue9}
                                onChange={handleRadioChange}
                                value={apivalue9}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 9' }}
                                />
                            <Radio
                                checked={apivalue === apivalue10}
                                onChange={handleRadioChange}
                                value={apivalue10}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 10' }}
                                />
                                <Radio
                                checked={apivalue === apivalue11}
                                onChange={handleRadioChange}
                                value={apivalue11}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 11' }}
                                />      
                                <Radio
                                checked={apivalue === apivalue12}
                                onChange={handleRadioChange}
                                value={apivalue12}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Value 12' }}
                                />            
                        </Box>
                        <div className='messagebox'>have you changed the api <Checkbox
                                                                                    checked={checked}
                                                                                    onChange={handleCheckboxChange}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    /> </div>
                        <Box my={1} >
                          <Grid container spacing={2}>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue1(e.target.value);}} value={apivalue1} required />
                                </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue2(e.target.value);}} value={apivalue2} required />
                                 </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue3(e.target.value);}} value={apivalue3} required />
                                </Grid>
                                  <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue4(e.target.value);}} value={apivalue4} required />
                                </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue5(e.target.value);}} value={apivalue5} required />
                                </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue6(e.target.value);}} value={apivalue6} required />
                                 </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue7(e.target.value);}} value={apivalue7} required />
                                </Grid>
                                  <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue8(e.target.value);}} value={apivalue8} required />
                                </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue9(e.target.value);}} value={apivalue9} required />
                                </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue10(e.target.value);}} value={apivalue10} required />
                                 </Grid>
                                <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue11(e.target.value);}} value={apivalue11} required />
                                </Grid>
                                  <Grid item xs={6}>
                                        <TextField type="text" name="apiKeyDone" align="right" style ={{width: '100%',}} label="Api Key" variant="outlined"  onChange={(e) => {setSecretvalue12(e.target.value);}} value={apivalue12} required />
                                </Grid>

                        </Grid>
                      </Box>


                    </form>
                    </Grid>

                    {/* Table */}
                    <Grid item xs={8}>

                    {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }

         
                    {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
                    <Grid item xs={12}>
                    <CirclesWithBar color="#FF00DF" ariaLabel="falling-lines-loading" />
                     </Grid> </Grid>: null }

                     {   superFinalData.length !== 0 ?
                         <PopulateSuperKgr populateData={selectedQuestionData} selectedRowsData={lengthData} copyToClipboard={dataCopyToClip} />
                          : null
                    }

                     
                    <DataTable columns={columns} data={superFinalData} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChangeSelected} conditionalRowStyles={conditionalRowStyles}  />
                    {/* <DataTable columns={columns} data={superFinalData} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChangeSelected}   /> */}
        

                        <Box my={7}>
                     <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
       
                     <iframe width="560" height="315" src="https://www.youtube.com/embed/Tfg60tfVEm4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    
                    
                     <Box mx={1} my={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center">

                      <Typography align="center">To download the file on how to create APIs, click on this button </Typography>
                      <a href="https://drive.google.com/file/d/1PQQkeRx2zmodAlJDnhkP8IpjJ7zlKxUx/view?usp=sharing" target="_blank" rel="noreferrer"><Button style={buttonStyle} variant="contained">Download</Button></a></Box>
 
                          </Grid>
                          </Box>


                     </Grid>
                </Grid>

       


    </Box>
</Container>
    </>
  )
}

export default PaidSuperFastKGR