import React, {useState, useEffect} from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link, Box, TextField, Button, Stack } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../Images/icon_logo.png";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from 'react-router';
import BeatLoader from "react-spinners/BeatLoader";



//////////////////////////////////
const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
//   height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
  marginTop: "50px",
  marginBottom: "20px"
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Passwordreset = () => {

  let navigate = useNavigate();

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [datas, setDatas] = useState([]);
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        license: "",
        licenseSystem: "",
      })

    const { firstName, lastName, email, password, license } = data

    const handleChange = e => {
         setData({ ...data, [e.target.name]: e.target.value }) 
    
        }

    const handleSubmit = async e => {
    e.preventDefault()
  setLoading(true)

  
  const requestBody = {
    email: email,
    newPassword: password,
    license: license
  };

    await axios.post("https://kwintentauthnew-h6lhegca5a-uc.a.run.app/auth/update-password",  requestBody , {
    
      headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'application/json'}
    })
      .then((response) => {
        setLoading(false)
        navigate("/login")
      }).catch((error) => {
        setLoading(false)
        setMessage(error.response.data.error)
        
      })
  
     


    }

 // https://authdoublecheck.herokuapp.com/frontend https://gunpal-api.onrender.com
    // const getData = async () => {
    //   const { data } = await axios.post(`https://gunpal-api.onrender.com/frontend`,  license , {
    //     headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'text/plain'}
    //   });
    //   setDatas(data);
    // };

    // useEffect(() => {

    //   if(license !== ""){
    //     getData();
    //   }


    // }, [license]);


console.log(datas)



  return (
    
    <RootStyle>
    <Container maxWidth="sm">
      <ContentStyle>
        <HeadingStyle component={motion.div} {...fadeInUp}>
        <Box>
        <Link to="/">
         <Box  component="img" src={Logo} alt="logo" />
          </Link>
    </Box>
          <Typography sx={{ color: "text.secondary", mb: 5 }}>
             Reset Password
          </Typography>
        </HeadingStyle>

        <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            
          >
            <TextField
              fullWidth
            
              type= "text"
              name="license"
              label="License Code"
              value={license}
              onChange={handleChange}
            />
            <TextField
              fullWidth
            
              type="email"
              name="email"
              label="Email Address"
              value={email}
              onChange={handleChange}
            />

            <TextField
              fullWidth
           
              type= "password"
              name="password"
              label="New Password"
              value={password}
              onChange={handleChange}
            />

      

{loading && <BeatLoader color="#FF00DF" className="my-1" />}

                <Button type="submit" variant="contained">Reset Password</Button>
                {message && <div className='message'>{message}</div>}
          </Box>
        </Box>
      </form>

        <Typography
          component={motion.p}
          {...fadeInUp}
          variant="body2"
          align="center"
          sx={{ mt: 3 }}
        >
         Already have an account?{" "}
          <Link variant="subtitle2" component={RouterLink} to="/login">
            Login
          </Link>
        </Typography>
      </ContentStyle>
    </Container>
  </RootStyle>
  );
};



export default Passwordreset