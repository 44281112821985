import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
  
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    Filler,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  

function LineChart(props) {

    const options = {
      maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }

      function getUpdatedLabels() {
        const labels = [];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-11 (0 for January, 11 for December)
        const currentYear = currentDate.getFullYear(); // Current year
    
        // Adjust the start month to be one month before the current month
        let adjustedMonth = currentMonth - 1;
        let adjustedYear = currentYear;
    
        // Handle case where we need to go back to December of the previous year
        if (adjustedMonth < 0) {
            adjustedMonth = 11; // December
            adjustedYear -= 1;
        }
    
        for (let i = 11; i >= 0; i--) {
            let month = new Date(adjustedYear, adjustedMonth - i, 1);
            let monthLabel = month.toLocaleString('default', { month: 'long' }) + ' ' + month.getFullYear();
            labels.push(monthLabel);
        }
    
        return labels;
    }
    
    const labels = getUpdatedLabels();
      // 
      // const labels = ['August 2023', 'September 2023', 'October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', "March 2024", 'April 2024', 'May 2024', 'June 2024',  'July 2024'];
      
       const data = {
        labels,
        datasets: [
          {
            label: 'Search Volume',
            data: props.data,
            fill: true,
            borderColor: '#FF8480',
            borderWidth: 1,
            backgroundColor: '#FFCDCC',
          }],
      };
      
  return <Line options={options} data={data}  />;
}

export default LineChart;
