import React, {useState} from 'react';
import { BallTriangle } from  'react-loader-spinner';
import {Box, Grid, TextField, Button, Container } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from "axios";
import PopulateReddit from "../Component/PopulateReddit";
import moment from 'moment';
import useLocalStorage from "use-local-storage";




const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    display: "block",
    width: "100%",
    // marginTop: "15px"
}

function RedditTool() {


    const [loading, setLoading] = useState();
    const [bulkIndexChecker, setBulkIndexChecker] = useState([]);
    const [messageerror, setMessageError] = useState(false);
    const [dataCopyToClip, setDataCopyToClip] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();
    // const [inputValue, setInputValue] = useState();
    // const [userApiKey, setUserApiKey] = useState();
// https://keywordintent-reddit.herokuapp.com 
//  subRedditValue, secretvalue, idValue
// f97fe239d5dc18989f8cf2732b0eaf02

    const [data, setData] = useState({    
    subRedditValue: "",
    // idValue: "",
    // secretvalue: "",
    pageScraper: ""
    })
    const [idValue, setIdValue] = useLocalStorage("id", "");
    const [secretvalue, setSecretvalue] = useLocalStorage("secret", "");




    const { subRedditValue, pageScraper } = data

    const inputhandleChange = e =>
      setData({ ...data, [e.target.name]: e.target.value })


console.log(subRedditValue, secretvalue, idValue, pageScraper)
  
   

   
   
    const  handleSubmit = async (e) => {
        e.preventDefault();
       // https://keywordintent-reddit.herokuapp.com/


       if(subRedditValue.length < 2 || idValue.length < 2 || secretvalue.length < 2 ){
        return;
      }

         await axios.post("https://keywordintent-m5tfx6i6ia-nn.a.run.app/reddithunter", {subRedditValue, idValue, secretvalue, pageScraper} , {
            headers: {'Access-Control-Allow-Origin': '*' , "Content-Type": "application/json" }
          })
            .then((response) => {
            console.log(response.data);
          setBulkIndexChecker(response.data)      
        //   console.log(bulkIndexChecker[0].data.title)
                // 5fTQjzz9-vfwk3Pv4y3deQ
                // q5607vDYWgeuDAd3JwSs5Q0uRDwBfg

            // setBulkIndexChecker(response.data);
            setLoading(false);
            setMessageError(false)
              
            }).catch((error) => {
            
              // console.log(error)
              setMessageError(true)
              setLoading(false);
            }) 
        } 

        // console.log(bulkIndexChecker);

        const customStyles = {
            headCells: {
              style: {
                backgroundColor: 'rgb(255, 0, 223)',
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: '17px',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
              },
            },
          };

 // 5fTQjzz9-vfwk3Pv4y3deQ
                // q5607vDYWgeuDAd3JwSs5Q0uRDwBfg

         //       var s = moment.unix(row.data.created).local().format("DD/MM/YYYY");

         var arr3 = bulkIndexChecker.map(function(item){
          return `https://www.reddit.com${item.data.permalink}`;
      });

      console.log(arr3)

          const columns = [
            {
            name: '# ',
            cell: (row, index) => index + 1,
            width: '56px',
            // sortFunction: caseInsensitiveSort
            },
            {
              name: 'Date',
              selector: row => moment.unix(row.data.created).local().format("DD/MM/YYYY"),
              width: '120px',
              // sortable: true,
           
          },
          {
              name: 'UpVotes',
              selector: row => row.data.ups,
              width: '120px',
              sortable: true,
              center: true,
              // sortable: true,
           
          },
            {
                name: 'Post Title',
                selector: row => row.data.title,
                width: '651px',
                // sortable: true, 'https://www.reddit.com"+${row.data.permalink}+"'
                // `cell: row => <a href="https://www.scraperapi.com/${row.data.permalink}" target="_blank" rel="noopener noreferrer nofollow">click here</a>`
             
            },
            {
                name: 'Post Url',
                selector: row => `https://www.reddit.com${row.data.permalink}`,
                width: '370px', 
                
            }
          ];



          const handleChange = ({ selectedRows }) => {
            // You can set state or dispatch with something like Redux so we can use the retrieved data
           
          
            
            var arr1 = selectedRows.map(function(item){
              return [item.data.ups, item.data.title, `https://www.reddit.com${item.data.permalink}`, item.data.selftext];
          });
          
          
          var arr2 = selectedRows.map(function(item){
            return item.data.title ;
          });
          
          setDataCopyToClip(arr2.join('\n'))
          
          setSelectedQuestionData(arr1)
          };  


          if(selectedQuestionData !== undefined && selectedQuestionData.length !== 0){

            var lengthData = selectedQuestionData.length
            }


  return (
    <Container maxWidth="xl" > 
    <Box >
   
    <Grid item md={12} mt={4}>
       
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} >
            {/* <TextField id="outlined-multiline-static" label="Multiline" multiline rows={4} style ={{width: '100%'}} onChange={(e) => setinputValue(e.target.value)} value={inputValue} required /> */}
            {/* <TextField style ={{width: '100%'}} onChange={(e) => setinpurValue(e.target.value)} value={inpurValue} placeholder="Enter the keyword" required /> */}
            {/* <TextField required style ={{width: '100%'}} label="Enter the keyword" variant="outlined" onChange={(e) => setinpurValue(e.target.value)}  /> */}
            <TextField required style ={{width: '100%'}} label="Enter SubReddit Name" variant="outlined"  onChange={inputhandleChange} name="subRedditValue" value={subRedditValue} />
            </Grid>
            
            <Grid item xs={6} md={2}>
            <TextField style ={{width: '100%'}} label="Page" variant="outlined" onChange={inputhandleChange} name="pageScraper" value={pageScraper} /> 
            </Grid>

            <Grid item xs={6} md={2}>
            <TextField style ={{width: '100%'}} label="App id" variant="outlined" onChange={(e) => {setIdValue(e.target.value);}} name="idValue" value={idValue} /> 
            </Grid>
            <Grid item xs={6} md={2}>
            <TextField style ={{width: '100%'}} label="App Secret" variant="outlined" onChange={(e) => {setSecretvalue(e.target.value);}} name="secretvalue" value={secretvalue} />
            </Grid>
            <Grid item xs={12} md={2}>
                     <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)} >SUBMIT</Button>
              </Grid>
          </Grid>
        </form>
       

{/* Form Div end */}
        {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


        {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }
 

<Box mt={7}>


{  bulkIndexChecker !== undefined && bulkIndexChecker.length !== 0 ?

<PopulateReddit populateData={selectedQuestionData} selectedRowsData={lengthData} copyToClipboard={dataCopyToClip} />
  : null
}


<DataTable columns={columns} data={bulkIndexChecker} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChange} />
</Box>


        </Grid>
      
        </Box> 
       </Container> 
  
  )
}

export default RedditTool;