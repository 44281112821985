import {BrowserRouter as Router, Route, Routes, Navigate , Outlet } from 'react-router-dom';
import Alerts from "./Component/Alerts"
import ResponsiveBar from "./Component/ButtonAppBar";
import Kgr from "./Pages/Kgr";
// import PaidKgr from "./Pages/PaidKgr";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { Box } from '@mui/material/';
import GoogleAutoSuggest from './Pages/GoogleAutoSuggest';
import Contact from './Pages/Contact';
import TawkTo from 'tawkto-react';
import React, {useState, useEffect} from 'react';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import Passwordreset from './Pages/Passwordreset';
// import PeopleAlsoAsk from './Pages/PeopleAlsoAsk';
import GoogleSuggestion from './Pages/GoogleSuggestion';
import PaidSuperFastKGR from './Pages/PaidSuperFastKGR';
import BulkPageIndexChecker from './Pages/BulkPageIndexChecker';
import GoogleScraper from './Pages/GoogleScraper';
import BingScraper from './Pages/BingScraper';
import RedditTool from './Pages/RedditTool';
// import KeywordBuilder from './Pages/KeywordBuilder';
// import Pricing from './Pages/Pricing';
import BulkIndexer from './Pages/BulkIndexer';
import GoogleQuora from "./Pages/GoogleQuora";
// import NewMainTool from "./Pages/NewMainKeywords";
// import NewMainUrl from "./Pages/NewMainUrl"
// import NewMainSite from "./Pages/NewMainSite"
// import NewMainUrlKeyword from "./Pages/NewMainUrlKeyword"
import Home from "./Pages/Home"


import GroupingTool from "./Pages/GroupingTool";
// import Seo from "./Pages/Seo";
import Footer from "./Component/Footer";
import PeopleAlsoAskSuperb from "./Pages/PeopleAlsoAskSuperb"
import CacheBuster from 'react-cache-buster';
// import { version } from '../package.json';
// import { BallTriangle } from  'react-loader-spinner';
import LoadingCacheCompo from "./Component/LoadingCacheCompo"

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF00DF',
    },
  },
});

const PrivateRoutes = () => {
  const [tokens, setTokens] = useState({ token: true });

  useEffect(() => {
    const tawkans = localStorage.getItem("huh");
    if (tawkans && tawkans.length !== 0) {
      setTokens({ token: true  });
      // console.log(tawk)
     
    } else {
      setTokens({ token: false  });
     
    }
    // console.log(tawk)
    
  }, []);

  return tokens.token ? <Outlet /> : <Navigate to='/signup' />;
};


const HomeRoutes = () => {
  const [tokens, setTokens] = useState({ token: true });

  useEffect(() => {
    const tawkans = localStorage.getItem("huh");
    if (tawkans && tawkans.length !== 0) {
      setTokens({ token: true  });
      // console.log(tawk)
     
    } else {
      setTokens({ token: false  });
     
    }
    // console.log(tawk)
    
  }, []);

  return tokens.token ? <Outlet /> : <Kgr />;
};


function App() {
  
  // const isProduction = 'production';


  // const initializeState = () =>  !(localStorage.getItem("tawk"));

  // const [tokens, setTokens] = useState(initializeState);
  const [dataas, setDataas] = useState({ token: false })


  


 
  

useEffect(() => {

   

  var tawk = new TawkTo('62ab69c17b967b117994ecb6', '1g5mpa8fp')

  tawk.onStatusChange((status) => 
  {
      // console.log(status)
  })

  // if (localStorage.getItem("tawk") === null) {
  //   setTokens(false);
  // } else {
  //   setTokens(true);
  // }

  const tawkans = localStorage.getItem("huh");
    if (tawkans && tawkans.length !== 0) {
      setDataas({ token: true });
    } else {
      setDataas({ token: false });
    }


 

}, []);






  return (
    <CacheBuster
    currentVersion="4.2.4"
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<LoadingCacheCompo />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <ThemeProvider theme={theme}>
    <Router>
    <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-between', maxHeight: 'md'  }} className="bigContainer">
    <Box>
      <ResponsiveBar tokens={dataas.token} />
       {dataas.token && <Alerts />} 
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Routes>
          {/* <Route exact path="/" element={tokens ?  <PaidKgr /> : <Kgr /> } />  */}
          <Route element={<HomeRoutes />}>
          {/* <Route exact path="/" element={ <PaidKgr /> } />  */}
          <Route exact path="/" element={ <Home /> } /> 
          </Route>
           <Route path="/youtube-auto-complete" element={<GoogleAutoSuggest />} />
           <Route path="/contact" element={<Contact />} />
           <Route path="/login" element={<Login />} />
           <Route path="/signup" element={<SignUp />} />
           <Route path="/password-reset" element={<Passwordreset />} />
          
           
          
           {/* <Route path="/newmaintool" element={<NewMainTool />} /> */}
           {/* <Route path="/people-also-ask" element={<PeopleAlsoAsk />} /> */}
           
           <Route path="/google-suggestion" element={<GoogleSuggestion />} />
           <Route path="/grouping-tool" element={<GroupingTool />} />
           <Route element={<PrivateRoutes/>}>

            
           {/* <Route exact path="/" element={ <PaidKgr /> } />  */}
           
           {/* <Route path="/keywordsurl" element={<NewMainUrlKeyword />} />
           <Route path="/url" element={<NewMainUrl />} />
           <Route path="/site" element={<NewMainSite />} /> */}

           <Route path="/people-also-ask" element={<PeopleAlsoAskSuperb />} />
           <Route path="/kgr" element={ <PaidSuperFastKGR /> } /> 
           <Route path="/bulk-link-index-checker" element={<BulkPageIndexChecker />} />  
           <Route path="/google-scraper" element={<GoogleScraper /> } /> 
           <Route path="/bing-scraper" element={<BingScraper /> } />
           <Route path="/quora-question-finder" element={<GoogleQuora />} />
           <Route path="/bulk-link-indexer" element={<BulkIndexer />} />
           <Route path="/reddit-topic-hunter" element={<RedditTool />} />
          
           </Route>
      
        </Routes>
        </Box>
      <Box>
        <Footer />
        
        </Box>
        
        </Box>
      
        </Router>
        </ThemeProvider>
        </CacheBuster>
  );
}

export default App;
